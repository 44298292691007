<template>
  <div class="button-group">
    <template v-for="(item, index) in buttonList" :key="index">
      <el-button
        :type="item.theme == '' ? 'primary' : item.theme"
        class="button-item"
        :icon="item.icon"
        :class="item.className"
        @click="buttonClick(item)"
        >{{ item.cnName }}</el-button
      >
    </template>
  </div>
</template>
<script>
export default {
  name: "button-group",
};
</script>
<script setup>
const props = defineProps({
  buttonList: {
    type: Array,
    default: () => [],
  },
});
const $emits = defineEmits(["buttonClick"]);
const buttonClick = (button) => {
  $emits("buttonClick", button);
};
</script>
<style lang="scss" scoped>
.button-group {
  width: 100%;
  padding: 15px 0;
  .button-item {
    margin-right: 10px;
  }
}
</style>
