import { createStore } from "vuex";
import router from "../Router";

import $common from "../Utils/commonUtils";
import {
    ElMessage as $message,
    ElMessageBox as $messageBox,
} from "element-plus";
import { commonService } from "@/ApiService/commonService";
import {
    userService,
    skillService,
    tagService,
} from "@/ApiService/userCenterService.ts";
export default {
    state: {
        tags: [],
        skills: []
    },

    mutations: {
        setTag(state, tag) {
            let key = -1
            state.tags.map((item, index) => {
                if (item.districtId = tag.districtId) {
                    key = index
                }
            })
            if (key != -1) {
                state.tags[key] = tag
            } else {
                state.tags.push(tag)
            }
        },
        setSkill(state, skill) {
            let key = -1
            state.skills.map((item, index) => {
                if (item.districtId = skill.districtId) {
                    key = index
                }
            })
            if (key != -1) {
                state.skills[key] = skill
            } else {
                state.skills.push(skill)
            }
        }
    },
    actions: {
        fetchTags(context, { districtId, callback }) {
            tagService.allLabels({ districtId }).then((res) => {
                if (res.code == 0) {
                    context.commit("setTag", { data: res.data, districtId });
                    if (callback) {
                        callback(res.data)
                    }
                } else {
                    $message.error(res.err);
                }
            });
        },
        fetchSkills(context, { districtId, callback }) {
            skillService.allSkills({ districtId }).then((res) => {
                if (res.code == 0) {
                    context.commit("setSkill", { data: res.data, districtId });
                    if (callback) {
                        callback(res.data)
                    }
                } else {
                    $message.error(res.err);
                }
            });
        }
    }
}