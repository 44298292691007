// 任务中心
export default [
  {
    path: "/TaskCenter/PurifierTask",
    name: "PurifierTask",
    component: () => import("@/Page/TaskCenter/PurifierTask.vue"),
    meta: { title: "保洁任务" },
  },
  {
    path: "/TaskCenter/PunchRecord",
    name: "PunchRecord",
    component: () => import("@/Page/TaskCenter/PunchRecord.vue"),
    meta: { title: "打卡记录" },
  },
  {
    path: "/TaskCenter/UserReportManage",
    name: "UserReportManage",
    component: () => import("@/Page/TaskCenter/UserReportManage.vue"),
    meta: { title: "用户上报管理人" },
  },
  {
    path: "/TaskCenter/UserReportTask",
    name: "UserReportTask",
    component: () => import("@/Page/TaskCenter/UserReportTask.vue"),
    meta: { title: "用户上报任务" },
  },
  {
    path: "/TaskCenter/PunchRecordDetail",
    name: "PunchRecordDetail",
    component: () =>
      import("@/Page/TaskCenter/PunchRecords/PunchRecordDetail.vue"),
    meta: { title: "打卡记录详情" },
  },
  {
    path: "/TaskCenter/PunchRecordList",
    name: "PunchRecordList",
    component: () =>
      import("@/Page/TaskCenter/PunchRecords/PunchRecordList.vue"),
    meta: { title: "打卡记录明细" },
  },
];
