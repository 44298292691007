import request from "@/Utils/requestUtils";
import $api from "@/Apis/api";

export const commonService = {
  sidebarData: function () {
    return request.get($api.common.sidebarData);
  },
  showWebPageAuthoritOutputCache() {
    return request.get($api.common.showWebPageAuthoritOutputCache);
  },
  showFieldWebpageAuthority: function (obj) {
    return request.post($api.common.showFieldWebpageAuthority, obj);
  },
  showButtonWebpageAuthority: function (obj) {
    return request.post($api.common.showButtonWebpageAuthority, obj);
  },
  getIdByPath: function (data) {
    return request.get($api.common.getIdByPath, { params: data });
  },
};
