<template>
  <div class="disrict-screen">
    <el-select v-model="districtId" value-key filterable @change="districtChange" :disabled="disabled">
      <el-option v-for="item in disricts" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  name: "district-screen",
};
</script>
<script setup>
import { onMounted, ref, watch } from "vue";
import { dropdownService } from "@/ApiService/dropdownService.js";
import templateHooks from "@/Hooks/templateHooks.js";
const { $message } = templateHooks();
const props = defineProps({
  districtId: Number || String,
  disabled: Boolean,
});
const districtId = ref();

const $emits = defineEmits(["update:districtId", "districtChange"]);
const disricts = ref([]);
const fetchDistricts = () => {
  return new Promise((resolve) => {
    dropdownService.getDistrict().then((res) => {
      if (res.code == 0) {
        resolve(res.data);
        disricts.value = res.data;
        console.log(disricts.value);
      } else {
        $message.error(res.err);
        resolve(false);
      }
    });
  });
};
const districtChange = (obj) => {
  $emits("update:districtId", obj);
  $emits("districtChange", obj);
};
watch(
  () => props.districtId,
  (val) => {
    districtId.value = props.districtId;
  },
  { immediate: true }
);
onMounted(() => {
  // if (props.districtId) {
  //   $emits("districtChange", props.districtId);
  // }
});
defineExpose({
  fetchDistricts,
});
</script>
