import request from "@/Utils/requestUtils.js";
import $api from "@/Apis/api";
export const dropdownService = {
  gridTree: (data) => {
    return request.get($api.dropdown.gridTree, { params: data });
  },
  specialGridTreeByAdd: (data) => {
    return request.get($api.dropdown.specialGridTreeByAdd, { params: data });
  },
  selectMatter: (data) => {
    return request.post($api.dropdown.selectMatter, data);
  },
  selectWorkGroup: (data) => {
    return request.post($api.dropdown.selectWorkGroup, data);
  },
  principalList: (data) => {
    return request.post($api.dropdown.principalList, data);
  },
  getObjectTree: (districtId) => {
    return request.get($api.dropdown.getObjectTree, { params: districtId });
  },
  getParentObject: (districtId) => {
    return request.get($api.dropdown.getParentObject, { params: districtId });
  },
  getToolTree: (districtId) => {
    return request.get($api.dropdown.getToolTree, { params: districtId });
  },
  selectAllAgent: (data) => {
    return request.get($api.dropdown.selectAllAgent, { params: data });
  }, //获取所有代理商
  selectPropertyByAgentId: (data) => {
    return request.get($api.dropdown.selectPropertyByAgentId, { params: data });
  }, //根据代理id获取物业
  getDistrict: (data) => {
    return request.post($api.dropdown.getDistrict, data);
  },
  getParentTools: (districtId) => {
    return request.get($api.dropdown.getParentTools, { params: districtId });
  },
  gridSceneTree: (districtId) => {
    return request.get($api.dropdown.gridSceneTree, { params: districtId });
  },
  selectDevOpsType: (data) => {
    return request.get($api.dropdown.selectDevOpsType, { params: data });
  },
  getSceneObject: (data) => {
    return request.post($api.dropdown.getSceneObject, data);
  },
  selectLabel: (data) => {
    return request.get($api.dropdown.selectLabel, { params: data });
  },
  getWageType: (data) => {
    return request.get($api.dropdown.getWageType, { params: data });
  },//获取时薪类型（下拉）
  getDistrictWage: (data) => {
    return request.get($api.dropdown.getDistrictWage, { params: data });
  },//获取站点时薪类型
  inspectScenesTree: data => {
    return request.get($api.dropdown.inspectScenesTree, { params: data });
  },
  getTaskCancelReason: data => {
    return request.get($api.dropdown.getTaskCancelReason, { params: data });
  }
};
