// 基础权限配置
export default [
  {
    path: "/systemSet/authSet/authManage",
    name: "authManage",
    component: () => import("@/Page/SystemSet/AuthSet/AuthManage/Index.vue"),
    meta: { title: "权限组管理" },
  },
  {
    path: "/systemSet/authSet/setPageAuth",
    name: "setPageAuth",
    component: () =>
      import("@/Page/SystemSet/AuthSet/AuthManage/SetPageAuth.vue"),
    meta: { title: "配置页面权限", noCache: true },
  },
  {
    path: "/systemSet/authSet/setPageConAuth",
    name: "setPageConAuth",
    component: () =>
      import("@/Page/SystemSet/AuthSet/AuthManage/SetPageConAuth.vue"),
    meta: { title: "配置页面内容权限", noCache: true },
  },
];
