// 用户中心配置
export default [
  {
    path: "/userCenter/userManage",
    name: "userManage",
    component: () => import("@/Page/UserCenter/UserManage.vue"),
    meta: { title: "人员管理" },
  },
  {
    path: "/userCenter/teamManage",
    name: "teamManage",
    component: () => import("@/Page/UserCenter/TeamManage.vue"),
    meta: { title: "班组管理", noCache: true },
  },

  {
    path: "/userCenter/skillManage",
    name: "skillManage",
    component: () => import("@/Page/UserCenter/SkillManage.vue"),
    meta: { title: "技能管理", noCache: true },
  },
  {
    path: "/userCenter/tagsManage",
    name: "tagsManage",
    component: () => import("@/Page/UserCenter/TagsManage.vue"),
    meta: { title: "标签管理", noCache: true },
  },
];
