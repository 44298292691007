<template>
  <el-date-picker
    style="width: 320px"
    value-key
    :clearable="props.clearable"
    type="datetimerange"
    :default-time="defaultTime"
    start-placeholder="开始时间"
    end-placeholder="结束时间"
    value-format="YYYY-MM-DD HH:mm:ss"
    :shortcuts="shortcutsList"
  />
</template>
<script setup>
import { ref } from "vue";
let value = ref("");
let shortcutsList = ref([
  {
    text: "今天",
    value: () => {
      const start = new Date();
      const end = new Date();
      start.setTime(new Date(new Date().toLocaleDateString()));
      return [start, end];
    },
  },
  {
    text: "昨天",
    value: () => {
      const start = new Date(new Date().toLocaleDateString());
      const end = new Date(new Date().toLocaleDateString());
      start.setTime(start.getTime() - 3600 * 1000 * 24);
      end.setTime(end.getTime() + 3600 * 1000 * 24 - 1000 - 86400000);
      return [start, end];
    },
  },
  {
    text: "近七天",
    value: () => {
      const start = new Date(new Date().toLocaleDateString());
      const end = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: "近30天",
    value: () => {
      const start = new Date(new Date().toLocaleDateString());
      const end = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: "本月",
    value: () => {
      const start = new Date(new Date().toLocaleDateString());
      const end = new Date();
      start.setTime(start.setDate(1));
      return [start, end];
    },
  },
  {
    text: "上月",
    value: () => {
      const start = new Date();
      const end = new Date();
      start.setTime(
        new Date(start.getFullYear(), start.getMonth(), 0).setDate(1)
      );
      end.setTime(new Date(end.getFullYear(), end.getMonth(), 0));
      return [start, end];
    },
  },
]);
const defaultTime = [
  new Date(2000, 1, 1, 0, 0, 0),
  new Date(2000, 2, 1, 23, 59, 59),
];
const props = defineProps({
  clearable: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped></style>
