// 页面管理配置
export default [
  {
    path: "/SystemSet/pageManage",
    name: "pageManage",
    component: () => import("@/Page/SystemSet/PageManage/Index.vue"),
    meta: { title: "页面管理" },
  },
  {
    path: "/SystemSet/pageConManage",
    name: "pageConManage",
    component: () => import("@/Page/SystemSet/PageManage/PageConManage.vue"),
    meta: { title: "页面内容管理" },
  },
];
