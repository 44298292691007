export default [
  {
    path: "/TaskCenter/TaskSet/PlanTask",
    name: "PlanTask",
    component: () => import("@/Page/TaskCenter/TaskSet/PlanTask/Index.vue"),
    meta: { title: "计划任务" },
  },
  {
    path: "/TaskCenter/TaskSet/DeviceTask",
    name: "DeviceTask",
    component: () => import("@/Page/TaskCenter/TaskSet/DeviceTask/Index.vue"),
    meta: { title: "设备维护任务" },
  },
  {
    path: "/TaskSet/AssignTask",
    name: "AssignTask",
    component: () => import("@/Page/TaskCenter/TaskSet/AssignTask/Index.vue"),
    meta: { title: "指派任务" },
  },
];
