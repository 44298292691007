// 基础信息
export default [
  {
    path: "/BasicInfo/RegionalGridManage",
    name: "RegionalGridManage",
    component: () => import("@/Page/BasicInfo/RegionalGridManage.vue"),
    meta: { title: "区域网格管理" },
  },
  {
    path: "/BasicInfo/SceneManage",
    name: "SceneManage",
    component: () => import("@/Page/BasicInfo/SceneManage.vue"),
    meta: { title: "场景管理" },
  },
  {
    path: "/BasicInfo/ObjectManage/ObjectDataManage",
    name: "ObjectDataManage",
    component: () => import("@/Page/BasicInfo/ObjectManage/ObjectDataManage.vue"),
    meta: { title: "对象数据管理" },
  },
  {
    path: "/BasicInfo/ObjectManage/ServeMatterManage",
    name: "ServeMatterManage",
    component: () =>
      import("@/Page/BasicInfo/ObjectManage/ServeMatterManage.vue"),
    meta: { title: "服务事项管理" },
  },
  {
    path: "/BasicInfo/ObjectManage/SceneObjectManage",
    name: "SceneObjectManage",
    component: () =>
      import("@/Page/BasicInfo/ObjectManage/SceneObjectManage.vue"),
    meta: { title: "场景对象管理" },
  },
  {
    path: "/BasicInfo/WorkManage/WorkDataManage",
    name: "WorkDataManage",
    component: () => import("@/Page/BasicInfo/WorkManage/WorkDataManage.vue"),
    meta: { title: "作业数据管理" },
  },
  {
    path: "/BasicInfo/WorkManage/ManageScheme",
    name: "ManageScheme",
    component: () => import("@/Page/BasicInfo/WorkManage/ManageScheme.vue"),
    meta: { title: "管理方案" },
  },
  {
    path: "/BasicInfo/ToolMange",
    name: "ToolMange",
    component: () => import("@/Page/BasicInfo/ToolMange.vue"),
    meta: { title: "工具管理" },
  },
];
