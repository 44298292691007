<template>
  <div class="qu-tree">
    <div class="top-input">
      <el-input v-model="filterText" placeholder="请输入关键字搜索">
        <template #prefix>
          <el-icon class="el-input__icon"><search /></el-icon>
        </template>
      </el-input>
    </div>
    <el-tree
      ref="treeRef"
      class="filter-tree"
      :data="treeData"
      :node-key="nodeKey"
      :props="defaultProps"
      :filter-node-method="filterNode"
      @node-click="nodeClick"
      :expand-on-click-node="false"
      highlight-current
      :default-expanded-keys="defaultExpandedKeys"
    >
      <template #default="{ node, data }">
        <div style="line-height: 16px">
          <span>{{ node.label }}</span>
        </div>
      </template>
    </el-tree>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  treeData: Array,
  defaultExpandedKeys: {
    type: Array,
    default: () => [0],
  },
  defaultProps: {
    type: Object,
    default: {
      children: "childNode",
      label: "name",
    },
  },
  nodeKey: {
    type: String,
    default: "id",
  },
});

const filterText = ref("");
const treeRef = ref();

const $emits = defineEmits(["nodeClick"]);
watch(filterText, (val) => {
  treeRef.value.filter(val);
});

const filterNode = (value, data) => {
  if (!value) return true;
  return data[props.defaultProps.label].indexOf(value) !== -1;
};
const nodeClick = (dom, node, cb) => {
  console.log(node);
  $emits("nodeClick", { dom, node, cb });
};
</script>
<style lang="scss" scoped>
.qu-tree {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.top-input {
  margin-bottom: 20px;
}
</style>
