import md5 from "js-md5";
import $router from "../Router";
import $store from "../Store";
const isProduction = process.env.NODE_ENV === "production";
export default {
  appVersion: "1.1.5",
  appVersionCode: 1075, //每次提交代码前更新 数字需要比上一次的数字大
  devKey: isProduction ? false : true,
  // 获取uuid
  getUser() {
    let loginInfo = sessionStorage.getItem("loginInfo");
    let obj = loginInfo
      ? JSON.parse(decodeURIComponent(escape(window.atob(loginInfo))))
      : "";
    return obj.userInfo || undefined;
  },
  getCompany() {
    let loginInfo = sessionStorage.getItem("loginInfo");
    let obj = loginInfo
      ? JSON.parse(decodeURIComponent(escape(window.atob(loginInfo))))
      : "";
    return obj.companyInfo || undefined;
  },
  getSignature() {
    // uuid加密协议
    let signature = "",
      uuid = this.getUser() ? this.getUser().uuid : "",
      phone = sessionStorage.getItem("phone"),
      timeStamp = new Date().getTime();
    if (uuid && phone) {
      signature = md5(
        md5(uuid).toLocaleUpperCase() +
          md5(
            new Number(timeStamp).toString() + new Number(phone).toString()
          ).toLocaleUpperCase()
      ).toLocaleUpperCase();
    }
    return {
      uuid: uuid,
      timeStamp: timeStamp,
      signature: signature,
    };
  },
  // 计算el-main类高度
  comElMainHeight() {
    let wHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    return wHeight - 124;
  },
  // 获取表头信息中的数据
  selectItems(tableHeader) {
    if (!tableHeader || tableHeader.length == 0) {
      return [];
    }
    let selectItems = {};
    tableHeader.map((item) => {
      if (item.fieldType == "select") {
        selectItems[item.enName] = [];
        item.param.split(";").map((subItem) => {
          const key = !isNaN(Number(subItem.split(":")[0]))
            ? Number(subItem.split(":")[0])
            : subItem.split(":")[0];
          selectItems[item.enName].push({
            key: key,
            value: subItem.split(":")[1],
          });
        });
      }
    });
    // console.log(selectItems);
    return selectItems;
  },
  fomatDateTime(timestam, type) {
    const date = new Date(Number(timestam));
    const Y = date.getFullYear();
    const M =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
    const D = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    const H = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
    const m =
      date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
    const s =
      date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
    if (type == "all") {
      return Y + "-" + M + "-" + D;
    }
    return Y + "-" + M + "-" + D + " " + H + ":" + m + ":" + s;
  },
  // 转化服务区域选中节点数据
  formatServiceAreaData(domRef) {
    if (!domRef) return null;
    const checkedNodes = domRef.value.getCheckedNodes();
    const serviceAreas = [];
    checkedNodes.map((item) => {
      const { gridId, id, isscene, level, name, sceneId } = item.data;
      serviceAreas.push({ gridId, id, isscene, level, name, sceneId });
    });
    return serviceAreas;
  },
  // 获取指定站点的技能
  fetchSkillsByDistrictId(districtId) {
    let skills = [];
    $store.state.userStore.skills.map((item) => {
      if (districtId == item.districtId) {
        skills = item.data;
      }
    });
    return skills;
  },
  fetchTagsByDistrictId(districtId) {
    let tags = [];
    $store.state.userStore.tags.map((item) => {
      if (districtId == item.districtId) {
        tags = item.data;
      }
    });
    return tags;
  },
};
