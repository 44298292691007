export default [
    {
        path: "/TaskCenter/PatrolTask/PatrolPlan",
        name: "PatrolPlan",
        component: () => import("@/Page/TaskCenter/PatrolTask/PatrolPlan.vue"),
        meta: { title: "巡检计划" },
    },
    {
        path: "/TaskCenter/PatrolTask/PatrolTask",
        name: "PatrolTask",
        component: () => import("@/Page/TaskCenter/PatrolTask/PatrolTask.vue"),
        meta: { title: "巡检任务总览" },
    },
    {
        path: "/TaskCenter/PatrolTask/PatrolTaskList",
        name: "PatrolTaskList",
        component: () => import("@/Page/TaskCenter/PatrolTask/PatrolTaskList.vue"),
        meta: { title: "巡检任务明细" },
    },
];