import { createStore } from "vuex";
import router from "../Router";

import $common from "../Utils/commonUtils";
import { commonService } from "@/ApiService/commonService";
import userStore from './userStore'

let pages = {},
  pageButtons = {};
router.options.routes[1].children.map((item) => {
  if (item.name) {
    pages[item.name] = [];
    pageButtons[item.name] = {
      tableButtons: [],
      rowButtons: [],
      allButtons: [],
    };
  }
});

const store = createStore({
  state() {
    return {
      sideList: new Map(), //页面导航栏
      tags: [],
      menus: [],
      tagList: [],
      allPageFields: pages, //页面表格字段
      allPageButtons: pageButtons, //页面按钮
      currentRoute: {},
    };
  },
  getters: {
    selectItems: (state) => {
      return $common.selectItems(
        state.allPageFields[router.currentRoute.value.name]
      );
    },
    currentPageFields: (state) => {
      let name = router.currentRoute.value.name;
      return router ? state.allPageFields[name] : [];
    },
    currentrRouteQuery: (state) => {
      return state.currentRoute.query;
    },
  },
  mutations: {
    inputTagList(state, obj) {
      state.tagList.push(obj)
    },
    outTagList(state, obj) {
      const key = state.tagList.indexOf(obj)
      key == -1 ? '' : state.tagList.splice(key, 1)
    },
    fetchButtons(state, obj) {
      let tableButtons = [],
        rowButtons = [];
      let { data, name } = obj;
      data.map((item) => {
        if (item.type == 0) {
          rowButtons.push(item);
        } else if (item.type == 1) {
          tableButtons.push(item);
        }
      });
      state.allPageButtons[name].allButtons = data;
      state.allPageButtons[name].tableButtons = tableButtons;
      state.allPageButtons[name].rowButtons = rowButtons;
    },
    fetchFields(state, { data, name }) {
      state.allPageFields[name] = data;
    },
    setSideList(state, data) {
      state.sideList.set(data.key, data.value);
    },
    setMenuList(state, data) {
      state.menus = data;
      data.map((item) => {
        state.sideList.set(
          item.path + "?wId=" + item.webId + "&pageType=" + item.type,
          item.title
        );
      });
    },
    changeTags(state, tags) {
      state.tags = tags;
    },
    changeRoute(state, route) {
      state.currentRoute = route;
    },
  },
  actions: {
    fetchButtons(context, { wId, name }) {
      return new Promise((resolve) => {
        commonService
          .showButtonWebpageAuthority({ webId: wId })
          .then((res) => {
            if (res.code == 0) {
              context.commit("fetchButtons", { data: res.data, name: name });
            }
            resolve(res.data);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    fetchFields(context, obj) {
      return new Promise((resolve) => {
        const { wId, name } = obj;
        const cb = obj.cb;
        commonService
          .showFieldWebpageAuthority({ webId: wId })
          .then((res) => {
            if (res.code == 0) {
              if (cb) {
                cb(res.data);
              }
              res.data.map((item) => {
                item.isFixed = false;
                return item;
              });
              // const localFields = localStorage.getItem(name + 'Fields')
              // const localFieldsArr = localFields ? JSON.parse(localFields) : []
              // console.log('---------本地字段缓存数据-----------');
              // console.log(localFieldsArr);
              // let returnFields = []
              // if (localFieldsArr.length != 0) {
              //   console.log('---------当前页面字段在本地缓存字段不为空-----------');
              //   localFieldsArr.map(localItem => {
              //     // 判断 本地缓存的字段是否还存在于服务端
              //     console.log('---------判断 本地缓存的字段是否还存在于服务端-----------');
              //     const localFieldIsExist = res.data.filter(filterItem => filterItem.id == localItem.id)
              //     if (localFieldIsExist.length != 0) {
              //       // 服务端仍存在当前本地字段
              //       console.log('---------服务端仍存在当前本地字段-----------');
              //       res.data.map(item => {
              //         // 找到该字段

              //         if (localItem.id == item.id) {
              //           console.log('---------对比字段id，找到该字段-----------');
              //           // 比较修改时间
              //           if (localItem.modifyTime != item.modifyTime) {
              //             // 修改时间不一致 将服务端字段插入到字段数组
              //             console.log('---------修改时间不一致 将服务端字段插入到字段数组-----------');
              //             returnFields.push(item)
              //           } else {
              //             // 修改时间 一致 以缓存字段为准
              //             console.log('---------修改时间 一致 以缓存字段为准-----------');
              //             returnFields.push(localItem)
              //           }
              //         }
              //       })
              //     }
              //   })
              //   res.data.map(originItem => {
              //     // 判断 服务端字段是否已缓存到本地
              //     console.log('---------判断 服务端字段是否已缓存到本地-----------');
              //     const originFieldIsExist = localFieldsArr.filter(filterItem => filterItem.id == originItem.id)
              //     if (originFieldIsExist.length == 0) {
              //       // 如果服务端未缓存 插入到字段数组
              //       console.log('---------如果服务端未缓存 插入到字段数组-----------');
              //       returnFields.push(originItem)
              //     }
              //   })
              // } else {
              //   console.log('---------当前页面字段在本地缓存字段为空-----------');
              //   returnFields = res.data
              // }
              // function sortArr(attr) {
              //   return function (a, b) {
              //     return a[attr] - b[attr]
              //   }
              // }
              // returnFields = returnFields.sort(sortArr('orderBy'))
              // localStorage.setItem(name + 'Fields', JSON.stringify(returnFields))
              // context.commit("fetchFields", { data: returnFields, name: name });
              context.commit("fetchFields", { data: res.data, name: name });
            }
            resolve(res.data);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
  },
  modules: {
    userStore
  }
});
export default store;
