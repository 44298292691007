import { createApp } from "vue";
import App from "./App.vue";
import Router from "./Router";
import Store from "./Store";
import elementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import { createFromIconfontCN } from "@ant-design/icons-vue";
import * as ElIconModules from "@element-plus/icons-vue";
import "default-passive-events";

import "./Router/router-guard";
import "./Utils/resizeWindow";
import common from "./Utils/commonUtils";

import quTable from "./Components/public/quTable/table.vue";
import quTree from "./Components/public/quTree/tree.vue";
import quButtonGroup from "./Components/public/quButttonGroup/buttonGroup.vue";
import districtScreen from "./Components/common/districtScreenCom.vue";
import quTimeSelect from "./Components/public/quTimeSelect/timeSelect.vue";


const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3265358_ow01d9xv3j.js", // 在 iconfont.cn 上生成
});
const app = createApp(App);
app.config.globalProperties.$store = Store;
app.config.globalProperties.$common = common;
app.use(Router);
app.use(Store);
app.use(elementPlus, { locale });
// 全局注册 antd 图标
app.component("MyIcon", MyIcon);
// 全局注册le-icon
for (const iconName in ElIconModules) {
  if (Reflect.has(ElIconModules, iconName)) {
    const item = ElIconModules[iconName];
    app.component(iconName, item);
  }
}
// 全局注册 qu-table组件
app.component("qu-table", quTable);
app.component("qu-tree", quTree);
app.component("qu-button-group", quButtonGroup);
app.component("district-screen", districtScreen);
app.component("qu-time-select", quTimeSelect);
app.mount("#app");
