const apiArr = {
  login: {
    sendCode: "/sms/sendCode",
    login: "/login",
    logOut: "/logOut",
  },
  page: {
    queryWebPageOutput: "/webpage/queryWebPageOutput", //配置页面列表查询
    deleteWebpage: "/webpage/deleteWebpage", //删除配置页面
    addWebpage: "/webpage/addWebpage", //新增配置页面
    updateWebpage: "/webpage/updateWebpage", //修改配置页面
    addButton: "/webpage/addButton", //页面配置按钮
    queryButtonByWeb: "/webpage/queryButtonByWeb", //获取页面按钮组
    deleteButton: "/webpage/deleteButton", //删除页面按钮
    updateButton: "/webpage/updateButton", //修改页面按钮
    addPageField: "/webpage/addPageField", //批量添加列
    deleteField: "/webpage/deleteField", //删除列
    updatePageField: "/webpage/updatePageField", //修改列
    queryFieldByWeb: "/webpage/queryFieldByWeb", //查询页面列
    addSingleField: "/webpage/addSingleField", //增加列
    addButtonField: "/webpage/addButtonField", //自动添加子页面列
    addSingleButtonField: "/webpage/addSingleButtonField", //人工单独添加子页面列
    queryButtonFieldByButton: "/webpage/queryButtonFieldByButton", //查询子页面字段
    deleteButtonField: "/webpage/deleteButtonField", //删除子页面字段
    updateButtonField: "/webpage/updateButtonField", //修改子页面字段关联
  },
  common: {
    sidebarData: "/webpage/showWebPageAuthoritOutput", //获取页面左侧菜单列表
    showWebPageAuthoritOutputCache: "/webpage/showWebPageAuthoritOutputCache", //获取页面缓存列表
    showFieldWebpageAuthority: "/webpage/showFieldWebpageAuthority", //获取权限组关联页面字段
    showButtonWebpageAuthority: "/webpage/showButtonWebpageAuthority", //获取权限组页面按钮
    getIdByPath: "webpage/getIdByPath", //根据路径查询webid
  },
  systemSet: {
    taskDistrictParamPageList: "/taskDistrictParam/taskDistrictParamPageList", //任务站点系统参数分页查询
    updateTaskDistrictParam: "/taskDistrictParam/updateTaskDistrictParam", // 修改任务站点系统参数
  },
  author: {
    queryAuthority: "/webpage/queryAuthority", //权限组列表
    addAuthority: "/webpage/addAuthority", //添加权限组
    updateAuthority: "/webpage/updateAuthority", //修改权限组,
    deleteAuthority: "/webpage/deleteAuthority", //删除权限组
    setWebPageAuthoritOutputConfig: "/webpage/setWebPageAuthoritOutputConfig", //全部页面
    addWebpageAuthority: "/webpage/addWebpageAuthority", //添加页面权限组关联
    queryButtonWebpageAuthorityConfig:
      "/webpage/queryButtonWebpageAuthorityConfig", //查询页面按钮关联
    addButtonWebpageAuthority: "/webpage/addButtonWebpageAuthority", //提交页面按钮权限配置
    queryFieldWebpageAuthorityConfig:
      "/webpage/queryFieldWebpageAuthorityConfig", //查询页面字段关联
    addFieldWebpageAuthority: "/webpage/addFieldWebpageAuthority", //添加权限列
    userPageList: "/userCenter/userAuthorityPageList", //获取所有用户
    setAuthorityUser: "/authority/setAuthorityUser", //添加权限组人员
    getAuthorityUserList: "/authority/getAuthorityUserList", //已选中权限组人员
    checkIsAgent: "/authority/checkIsAgent", //验证是否行权限
  },
  //基础信息
  basicInfo: {
    //区域网格管理
    regionalGridManage: {
      pageList: "/grid/pageList", // 列表
      update: "/grid/update", // 编辑
      delete: "/grid/delete", // 删除
      add: "/grid/add", // 提交/grid
      selectList: "/userCenter/selectList", // 负责人选择
      uploadFile: "/grid/uploadFile", // 上传文件
      batchAdd: "/grid/batchAdd", // 开始导入
      export: "/grid/export", // 导出
    },
    // 场景管理
    sceneManage: {
      pageList: "/scenes/pageList",
      update: "/scenes/update",
      delete: "/scenes/delete",
      add: "/scenes/add",
      export: "/scenes/export",
    },
    // 对象数据管理
    objectDataManage: {
      objectPageList: "/object/objectPageList",
      uploadFileObject: "/object/uploadFileObject",
      removeObject: "/object/removeObject",
      addOrUpdateObject: "/object/addOrUpdateObject",
      objectExport: "/object/objectExport",
      batchAddObject: "/object/batchAddObject",
    },
    //服务事项管理
    serveMatterManage: {
      matterPageList: "/matter/matterPageList",
      addMatter: "/matter/addMatter",
      updateMatter: "/matter/updateMatter",
      removeMatter: "/matter/removeMatter",
      matterExport: "/matter/matterExport",
    },
    //场景对象管理
    sceneMatterManage: {
      sceneObjectPageList: "/sceneObject/sceneObjectPageList",
      setSceneObject: "/sceneObject/setSceneObject",
      updateSceneObject: "/sceneObject/updateSceneObject",
      removeSceneObject: "/sceneObject/removeSceneObject",
      sceneObjectExport: "/sceneObject/sceneObjectExport",
    },
    // 作业管理
    operationManage: {
      oprationPageList: "/operation/oprationPageList",
      addOperation: "/operation/addOperation",
      updateOperation: "/operation/updateOperation",
      removeOperation: "/operation/removeOperation",
      operationExport: "/operation/operationExport",
    },
    // 工具管理
    toolMange: {
      toolsPageList: "/tools/toolsPageList",
      addOrUpdateTools: "/tools/addOrUpdateTools",
      removeTools: "/tools/removeTools",
      toolsExport: "/tools/toolsExport",
    },
  },
  // 用户中心
  userCenter: {
    // 人员管理
    userManage: {
      pageList: "/userCenter/pageList",
      addOrUpdate: "/userCenter/addOrUpdate",
      userSelectList: "/userCenter/userSelectList",
      export: "/userCenter/export",
      getBaseAppMenu: "/userCenter/getBaseAppMenu",
      getMyBaseMenu: "/userCenter/getMyBaseMenu",
      getDistrictAppMenu: "/userCenter/getDistrictAppMenu",
      getMyMenu: "/userCenter/getMyMenu",
      delete: "/userCenter/delete",
    },
    // 班组管理
    workGroup: {
      pageList: "/workGroup/pageList",
      addOrUpdate: "/workGroup/addOrUpdate",
      deleteWorkGroup: "/workGroup/deleteWorkGroup", //删除班组
      memberPageList: "/workGroup/memberPageList", //查看班组人员
      addMember: "/workGroup/addMember", //新增班组人员
      addorUpdateMember: "/workGroup/addorUpdateMember",
      memberList: "/workGroup/memberList",
      groupExport: "/workGroup/groupExport",
    },
    // 技能管理
    skillManage: {
      pageList: "/skill/pageList", //列表查询接口：
      delete: "/skill/delete", //删除接口：
      addOrUpdate: "/skill/addOrUpdate", //编辑接口：
      allSkills: "/skill/allSkills",
    },
    // 标签管理
    tagManage: {
      pageList: "/label/pageList", //列表查询接口：
      delete: "/label/delete", //删除接口：
      addOrUpdate: "/label/addOrUpdate", //编辑接口：
      allLabels: "/label/allLabels",
    },
  },
  // 任务中心
  taskCenter: {
    taskSet: {
      deviceTask: {
        planUserList: "/plan/planUserList", //查询计划可设置人员
        getPlanUserList: "/plan/getPlanUserList", //已选中计划人员
        addDevOpsPlan: "/plan/addDevOpsPlan", //添加设备维护计划
        planDevOpsPageList: "/plan/planDevOpsPageList", //查询列表
        removePlan: "/plan/removePlan", //删除
        updateDevOpsPlan: "/plan/updateDevOpsPlan", //编辑
        planDevopsExport: "/plan/planDevopsExport", //导出
      },
      planTask: {
        addOrUpdateRoutinePlan: "/plan/addOrUpdateRoutinePlan", //添加计划任务
        planPageList: "/plan/planPageList",
        planoprationPageList: "/operation/planoprationPageList",
        setPlanStatus: "/plan/setPlanStatus",
        getPlanListInfo: "/plan/getPlanListInfo",
        getRoutinePlanWorkGroupList: "/plan/getRoutinePlanWorkGroupList",
        getPlanWorkGroupList: "/plan/getPlanWorkGroupList",
        setPlanFlag: "/plan/setPlanFlag",
      },
      assignTask: {
        planPageList: "/plan/planPageList", //
        removePlan: "/plan/removePlan", //
        addOrUpdateAppointPlan: "/plan/addOrUpdateAppointPlan", //
        setPlanStatus: "/plan/setPlanStatus", //
        appointPlanUserList: "/plan/appointPlanUserList", //
        getPlanUserList: "/plan/getPlanUserList", //
        planAppointExport: "/plan/planAppointExport", //
        planAppointExport: "/plan/planAppointExport", //
        getRoutinePlanWorkGroupList: "/plan/getRoutinePlanWorkGroupList", //
        getPlanWorkGroupList: "/plan/getPlanWorkGroupList",
      },
    },
    watchPunchRecordDetail:{
      pageList:'/punchRecordDayStatis/pageList',
      export:'/punchRecordDayStatis/export'
    },
    watchPunchRecordList:{
      pageList:'/punchRecord/pageList',
      export:'/punchRecord/export'
    },
    // 打卡记录
    attendance: {
      pageList: "/attendance/pageList",
      export: "/attendance/export",
    },
    // 保洁任务
    purifierTask: {
      pageList: "/task/pageList",
      export: "/task/export",
      look: "/task/look",
      cancel: "/task/cancel",
    },
    // 用户上报管理人
    userReport: {
      pageList: "/escalationUser/pageList",
      export: "/escalationUser/export",
      userSelectList: "/userCenter/userSelectList",
      add: "/escalationUser/add",
      update: "/escalationUser/update",
      delete: "/escalationUser/delete",
    },
    // 巡检任务
    patrolTask: {
      statistics: '/taskInspect/statistics',//统计查询：
      pageList: '/taskInspect/pageList',//列表查询：
      export: '/taskInspect/export',//导出：
      cancel: '/taskInspect/cancel',//取消任务：
      look: '/taskInspect/look',//查看任务：
      scenesPageList: '/taskInspect/scenesPageList',//明细列表
      scenesExport: '/taskInspect/scenesExport',//明细导出
      lookInspectDetail: '/taskInspect/lookInspectDetail',//巡检点详情
      inspectPlanList: '/plan/inspectPlanList',//巡检计划查询
      planInspectExport: '/plan/planInspectExport',//巡检计划导出
      setWageHout: '/plan/setWageHout'//计划工时设置

    },
     // 用户上报任务
     userReportTask: {
      pageList: "/taskUserUpload/pageList",
      export: "/taskUserUpload/export",
      delete: "/taskUserUpload/delete",
      cancel: "/taskUserUpload/cancel",
      look: "/taskUserUpload/look",
      wageSet: "/taskUserUpload/wageSet",
      setTaskOrder: "/task/setTaskTaskOrder", // 优先级设置
    },
    // 计划优先级设置
    planSetPlanTaskOrder:{
      setPlanTaskOrder: "/plan/setPlanTaskOrder",
    }
  },
  // 人效参数配置
  configCenter: {
    HEConfig: {
      addOrUpdateWage: '/wage/addOrUpdateWage',//编辑时薪积分标准
      wagePageList: '/wage/wagePageList',//查询时薪积分标准
      getAmount: '/wage/getAmount',//根据积分获取对应时薪
      setWagePoint: '/wage/setWagePoint',//设置积分薪资配置
      wagePointPageList: '/wage/wagePointPageList'//查询薪资积分映射
    }
  },
  // 人效中心
  personEfficiencyCenter:{
    taskStatistics:{
      taskEfficiencyPageList: '/efficiency/taskEfficiencyPageList',//任务人效查询
      taskEfficiencyStatistics: '/efficiency/taskEfficiencyStatistics',//人效统计
      taskEfficiencyExport: '/efficiency/taskEfficiencyExport',//任务人效导出
    },
    personStatistics:{
      userEfficiencyPageList: '/efficiency/userEfficiencyPageList',//人员人效查询
      setUserEfficiencyReport: '/efficiency/setUserEfficiencyReport',//生成人效报表
      userEfficiencyExport: '/efficiency/userEfficiencyExport',//人员人效导出
    },
    districtStatistics:{
      districtEfficiencyList: '/efficiency/districtEfficiencyList',//站点人效查询
      districtEfficiencyExport: '/efficiency/districtEfficiencyExport',//站点人效导出
    },
    efficiencyStatement:{
      efficiencyRecordingPageList: '/efficiency/efficiencyRecordingPageList',//人效报表查询
      efficiencyRecordingExport: '/efficiency/efficiencyRecordingExport',//人效报表导出
      efficiencyDetailList: '/efficiency/efficiencyDetailList',//人效报表详情查询
      setEfficiencyDetailPoint: '/efficiency/setEfficiencyDetailPoint',//人效报表积分设置
      efficiencyDetailExport: '/efficiency/efficiencyDetailExport',//人效报表详情导出
    }
  },

  // 下拉菜单
  dropdown: {
    gridTree: "/dropDown/gridTree",
    specialGridTreeByAdd: "/dropDown/specialGridTreeByAdd",
    selectMatter: "/dropDown/selectMatter",
    selectWorkGroup: "/dropDown/selectWorkGroup",
    principalList: "/dropDown/principalList", // 负责人下拉
    oneScenesList: "/dropDown/oneScenesList",
    getObjectTree: "/dropDown/getObjectTree",
    getParentObject: "/dropDown/getParentObject",
    getToolTree: "/dropDown/getToolTree",
    selectAllAgent: "/dropDown/selectAllAgent", //获取所有代理商
    selectPropertyByAgentId: "/dropDown/selectPropertyByAgentId", //根据代理id获取物业
    getDistrict: "/dropDown/getDistrict",
    getParentTools: "/dropDown/getParentTools",
    gridSceneTree: "/dropDown/gridSceneTree",
    selectDevOpsType: "/dropDown/selectDevOpsType", //任务名称下拉查询
    getSceneObject: "/dropDown/getSceneObject",
    selectLabel: "/dropDown/selectLabel", //获取标签列表
    getWageType: '/dropDown/getWageType',//获取时薪类型（下拉）
    getDistrictWage: '/dropDown/getDistrictWage',//获取站点时薪类型
    inspectScenesTree: '/dropDown/inspectScenesTree',// 巡检路线：
    getTaskCancelReason: '/dropDown/getTaskCancelReason'//获取取消原因列表
  },
};
export default apiArr;
