// 人效中心
export default [
  {
    path: "/PersonEfficiencyCenter/TaskStatistics",
    name: "TaskStatistics",
    component: () => import("@/Page/PersonEfficiencyCenter/TaskStatistics.vue"),
    meta: { title: "任务人效统计" },
  },
  {
    path: "/PersonEfficiencyCenter/PersonStatistics",
    name: "PersonStatistics",
    component: () =>
      import("@/Page/PersonEfficiencyCenter/PersonStatistics.vue"),
    meta: { title: "人员人效统计" },
  },
  {
    path: "/PersonEfficiencyCenter/DistrictStatistics",
    name: "DistrictStatistics",
    component: () =>
      import("@/Page/PersonEfficiencyCenter/DistrictStatistics.vue"),
    meta: { title: "站点人效统计" },
  },
  {
    path: "/PersonEfficiencyCenter/EfficiencyStatement",
    name: "EfficiencyStatement",
    component: () =>
      import("@/Page/PersonEfficiencyCenter/EfficiencyStatement.vue"),
    meta: { title: "人效报表" },
  },
];
