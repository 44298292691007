// 人效参数配置
export default [
    {
        path: "/ConfigCenter/HEConfig/PointWagesConfig",
        name: "PointWagesConfig",
        component: () => import("@/Page/ConfigCenter/HEConfig/PointWagesConfig.vue"),
        meta: { title: "积分薪资配置" },
    },
    {
        path: "/ConfigCenter/HEConfig/ConfigureWorkCredits",
        name: "ConfigureWorkCredits",
        component: () => import("@/Page/ConfigCenter/HEConfig/ConfigureWorkCredits.vue"),
        meta: { title: "工时积分配置", noCache: true },
    },
];