import { createRouter, createWebHashHistory } from "vue-router";
// import baseAuthRouter from "./module/systemSet/baseAuthRouter";
// import pageManageRouter from "./module/pageManageRouter";
import systemSetRouter from "./module/systemSetRouter";
import basicInfoRouter from "./module/basicInfoRouter";
import userCenterRouter from "./module/userCenterRouter";
import taskCenterRouter from "./module/taskCenterRouter";
import taskRouter from "./module/taskRouter";
import HEConfigRoter from './module/configCenter/HEConfigRouter';
import personEfficiencyCenterRouter from './module/personEfficiencyCenterRouter';

let router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: "/main",
    },
    {
      path: "/",
      component: () => import("../Page/Home.vue"),
      meta: { title: "智慧清洁" },
      children: [
        {
          path: "/main",
          name: "main",
          component: () => import("../Page/MainPage.vue"),
        },
        ...systemSetRouter.baseAuthRouter,
        ...systemSetRouter.pageManageRouter,
        ...basicInfoRouter, //基础信息
        ...userCenterRouter, //用户中心
        ...taskCenterRouter, //任务中心
        ...taskRouter.planTaskRouter, //计划中心-任务设置-计划任务设置
        ...taskRouter.patrolTaskRouter,//计划中心-巡检任务
        ...systemSetRouter.systemParamSetRouter, //系统参数设置
        ...HEConfigRoter,//人效参数配置
        ...personEfficiencyCenterRouter, //人效中心
      ],
    },
    {
      path: "/login",
      component: () => import("../Page/Login.vue"),
      meta: { title: "登录", transition: "zoom" },
    },
  ],
});
export default router;
