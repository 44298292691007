// import { useRouter } from "vue-router";
import $router from "../Router";
import axios from "axios";
import { ElMessage as message, ElLoading as $loading } from "element-plus";
import $common from "./commonUtils";

const devKey = $common.devKey;
const baseUrl = devKey ? "/api" : import.meta.env.VITE_APP_API_ORIGIN;
const service = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  responseType: "json",
  timeout: 15000,
});
let switchLoading;
service.interceptors.request.use(
  (config) => {
    // 清除请求种的空字符串及无效值。除formData类型外。
    let strData = JSON.stringify(config.data);
    let data2 = {};
    if (strData != "{}" && strData != undefined) {
      for (let i in config.data) {
        if (!(config.data[i] === "" || config.data[i] === null)) {
          if (
            JSON.stringify(config.data[i]) == "[]" ||
            JSON.stringify(config.data[i]) == "{}"
          ) {
          } else {
            data2[i] = config.data[i];
          }
        }
      }
    }
    const wId = $router.currentRoute.value.query.wId;
    if (wId) {
      data2.webId = wId;
    }
    config.data = data2;
    if (config.responseType == "blob") {
      switchLoading = $loading.service({
        lock: true,
        text: "正在努力准备数据中~",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      config.timeout = 30000;
    }
    // uuid加密协议
    let sigData = $common.getSignature();
    config.headers = {
      ...sigData,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    if (response.config.responseType == "blob") {
      setTimeout(() => {
        switchLoading.close();
      }, 1000);
    }
    if (response.status === 200) {
      if (!response.data) {
        message.error("返回数据格式有误！");
        return Promise.reject(response);
      }
      if (response.data.code == 0) {
        return Promise.resolve(response.data);
      } else if (response.data.code == 104) {
        sessionStorage.removeItem("loginInfo");
        sessionStorage.removeItem("phone");
        $router.replace("/login").catch((err) => {
          console.log(err);
        });
        return Promise.reject();
      } else if (response.data.code == 112) {
        console.log("当前系统时间有偏差，请校准时间后重新访问！");
        message.error("当前系统时间有偏差，请校准时间后重新访问！");
        return Promise.reject(response);
      } else if (response.data.code == 113) {
        message.error("用户指纹无效，请重新登录");
        sessionStorage.removeItem("loginInfo");
        sessionStorage.removeItem("phone");
        $router.replace("/login").catch((err) => {
          console.log(err);
        });
        return Promise.reject(response);
      } else {
        // $message.error(response.data.err);
        return Promise.resolve(response.data);
      }
    } else {
      throw new Error();
    }
  },
  (error) => {
    message.error(error.message);
    if (switchLoading) {
      setTimeout(() => {
        switchLoading.close();
      }, 1000);
    }
    throw new Error(error);
  }
);
service.download = function (tableName, res) {
  /**拦截导出数据条数过多 */
  console.log(res);
  if (res.code === 0) {
    message.success(res.data);
    return;
  }
  let alink = document.createElement("a");
  const date = new Date();
  const allDate = $common.fomatDateTime(date).replace(/[-:\s]/g, "");
  alink.download = `${tableName}${allDate}.xlsx`;
  alink.style.display = "none";
  try {
    alink.href = window.URL.createObjectURL(res);
  } catch (error) {
    alink.href = res;
  }
  document.body.appendChild(alink);
  alink.click();
  document.body.removeChild(alink);
};
service.baseUrl = baseUrl;
export default service;
