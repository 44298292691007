import NProgress from "nprogress";
import router from "../Router";
import store from "../Store";

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 智慧清洁SaaS云平台`;
  NProgress.start();
  const role = sessionStorage.getItem("loginInfo");
  if (!role && to.path !== "/login") {
    next("/login");
  } else {
    // console.log(to);
    store.commit("changeRoute", to);
    next();
  }
});
router.afterEach((to) => {
  // 在即将进入新的页面组件前，关闭掉进度条
  if (to.query.wId && to.query.pageType == 0) {
    let fields = store.getters.currentPageFields || [];
    if (fields.length == 0) {
      store.dispatch("fetchFields", { wId: to.query.wId, name: to.name });
    }
    let buttons = store.state.allPageButtons[to.name]
      ? store.state.allPageButtons[to.name].allButtons
      : [];
    if (buttons.length == 0) {
      let timeOut = setTimeout(() => {
        store.dispatch("fetchButtons", { wId: to.query.wId, name: to.name });
        clearTimeout(timeOut);
      }, 100);
    }
  }
  NProgress.done();
});
