import request from "@/Utils/requestUtils.js";
import $api from "@/Apis/api";
interface List {
  page: Number;
  rows: Number;
  sord: String;
  sidx: String;
}
export const userService = {
  pageList: (data: List) => {
    return request.post($api.userCenter.userManage.pageList, data);
  },
  addOrUpdate: (data: any) => {
    return request.post($api.userCenter.userManage.addOrUpdate, data);
  },
  userSelectList: (data: List) => {
    return request.post($api.userCenter.userManage.userSelectList, data);
  },
  export: (data: any) => {
    request
      .post($api.userCenter.userManage.export, data, {
        responseType: "blob",
      })
      .then((res) => {
        request.download("人员数据列表记录", res);
      });
  },
  getBaseAppMenu: data => {
    return request.get($api.userCenter.userManage.getBaseAppMenu, { params: data })
  },
  getMyBaseMenu: data => {
    return request.get($api.userCenter.userManage.getMyBaseMenu, { params: data })
  },
  getDistrictAppMenu: data => {
    return request.post($api.userCenter.userManage.getDistrictAppMenu, data)
  },
  getMyMenu: data => {
    return request.get($api.userCenter.userManage.getMyMenu, { params: data })
  },
  delete: data => {
    return request.post($api.userCenter.userManage.delete, data)
  },
};
export const workGroupService = {
  pageList: (data: List) => {
    return request.post($api.userCenter.workGroup.pageList, data);
  },
  addOrUpdate: (data: any) => {
    return request.post($api.userCenter.workGroup.addOrUpdate, data);
  },
  deleteWorkGroup: (data: any) => {
    return request.post($api.userCenter.workGroup.deleteWorkGroup, data);
  },
  memberPageList: (data: List) => {
    return request.post($api.userCenter.workGroup.memberPageList, data);
  },
  addMember: (data: any) => {
    return request.post($api.userCenter.workGroup.addMember, data);
  },
  addorUpdateMember: (data: any) => {
    return request.post($api.userCenter.workGroup.addorUpdateMember, data);
  },
  memberList: (data: List) => {
    return request.post($api.userCenter.workGroup.memberList, data);
  },
  groupExport: (data: any) => {
    request
      .post($api.userCenter.workGroup.groupExport, data, {
        responseType: "blob",
      })
      .then((res) => {
        request.download("班组数据列表记录", res);
      });
  },
};
export const skillService = {
  pageList: (data: List) => {
    return request.post($api.userCenter.skillManage.pageList, data);
  }, //列表查询接口：
  delete: (data: any) => {
    return request.post($api.userCenter.skillManage.delete, data);
  }, //删除接口：
  addOrUpdate: (data: any) => {
    return request.post($api.userCenter.skillManage.addOrUpdate, data);
  },
  allSkills: (data: any) => {
    return request.post($api.userCenter.skillManage.allSkills, data);
  },
};
export const tagService = {
  pageList: (data: List) => {
    return request.post($api.userCenter.tagManage.pageList, data);
  }, //列表查询接口：
  delete: (data: any) => {
    return request.post($api.userCenter.tagManage.delete, data);
  }, //删除接口：
  addOrUpdate: (data: any) => {
    return request.post($api.userCenter.tagManage.addOrUpdate, data);
  },
  allLabels: (data: any) => {
    return request.post($api.userCenter.tagManage.allLabels, data);
  },
};
