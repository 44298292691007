import { ref, reactive, computed, getCurrentInstance, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import {
  ElMessage as $message,
  ElMessageBox as $messageBox,
} from "element-plus";
export default function (fetchData, buttonGroupRef, screenRef) {
  const tableData = ref([]);
  const pageProps = reactive({
    page: 1,
    rows: 20,
    records: 0,
    sidx: "id",
    sord: "desc",
  });
  const currentButton = reactive({
    data: {},
  });
  const currentRow = reactive({
    data: {},
  });
  const buttonsClickFun = reactive({
    data: {},
  });
  const windowHeight = ref(document.body.clientHeight);
  const screenForm = reactive({});
  const screenFormRef = ref();
  const shortcuts = ref([
    {
      text: "今天",
      value: () => {
        const date = new Date();
        const startDateTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          0,
          0,
          0
        );
        return [startDateTime, date];
      },
    },
    {
      text: "昨天",
      value: () => {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        const startDateTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          0,
          0,
          0
        );
        const endDateTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          23,
          59,
          59
        );
        return [startDateTime, endDateTime];
      },
    },
    {
      text: "近7天",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      },
    },
    //
    {
      text: "近30天",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      },
    },
    //
    {
      text: "本月",
      value: () => {
        const date = new Date();
        const startDateTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          1,
          0,
          0,
          0
        );
        return [startDateTime, date];
      },
    },
    {
      text: "上月",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      },
    },
  ]);

  const $store = useStore();
  const $route = useRoute();
  const $router = useRouter();

  const $common =
    getCurrentInstance().appContext.config.globalProperties.$common;
  const tableButtons = computed(() => {
    return $store.state.allPageButtons[$route.name]
      ? $store.state.allPageButtons[$route.name].tableButtons
      : [];
  });
  const rowButtons = computed(() => {
    return $store.state.allPageButtons[$route.name]
      ? $store.state.allPageButtons[$route.name].rowButtons
      : [];
  });

  const tableHeader = computed({
    get() {
      return $store.state.allPageFields[$route.name] || [];
    },
    set(val) {
      $store.commit("fetchFields", { data: val, name: $route.name });
    },
  });
  const selectItems = computed(() => {
    return $store.getters.selectItems;
  });
  const user = computed(() => {
    let userObj = $common.getters();
    return userObj ? userObj : {};
  });
  // @screenFormRefDom 表单数据
  // @notResetArr 重置白名单 无需重置的字段
  const clearScreen = (screenFormRefDom, notResetArr = []) => {
    // screenFormRefDom.resetFields();
    console.log(notResetArr);
    console.log(screenForm);
    for (let i in screenForm) {
      if (notResetArr.indexOf(i) == -1) {
        screenForm[i] = null;
        console.log(i);
      }
    }
    nextTick(() => {
      restSearch();
    });
  };
  const restSearch = () => {
    pageProps.page = 1;
    nextTick(() => {
      fetchData();
    });
  };

  const pageChange = ({ page, rows }) => {
    console.log(page, rows);
    pageProps.rows = rows;
    pageProps.page = page;
    fetchData();
  };
  const sortChange = ({ sord, sidx }) => {
    console.log({ sord, sidx });
    pageProps.sord = sord;
    pageProps.sidx = sidx;
    fetchData();
  };

  return {
    tableData,
    pageProps,
    tableButtons,
    rowButtons,
    tableHeader,
    selectItems,
    user,
    currentButton,
    currentRow,
    buttonsClickFun,
    screenForm,
    screenFormRef,
    $store,
    $route,
    $router,
    $common,
    clearScreen,
    restSearch,
    pageChange,
    sortChange,
    $message,
    $messageBox,
    windowHeight,
    shortcuts,
  };
}
